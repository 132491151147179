
import hasModel from '@/mixins/hasModel'
import impression from '@/mixins/impression'
import handleRouterLinkClick from '@/mixins/handleRouterLinkClick'
import hasContentMeta from '~/mixins/hasContentMeta'

export default {
    mixins: [hasModel, hasContentMeta, impression, handleRouterLinkClick],

    props: {
        hideSaveButton: Boolean,
        preventLink: Boolean,
        forcedHover: {
            type: Boolean,
            default: false,
        },
        small: Boolean,
    },

    data() {
        return {
            rowsHeight: 0,
            isDdOpen: false,
            imageRatio: 1.33,
        }
    },

    computed: {
        link() {
            return this.preventLink ? { name: this.$route.name, params: this.$route.params, query: this.$route.query } : '/vendors/' + this.model.slug
        },

        location() {
            if (this.locations?.length) {
                let location = ''
                const el = this.locations[0]
                if (el.metas?.country) {
                    const state = ['USA', 'United States'].includes(el.metas.country) ? el.metas.state : el.metas.country
                    const city = el.metas.city
                    location = state && city ? `${city}, ${state}` : el.name
                } else location = el.name
                return location
            }

            return null
        },

        service() {
            return this.services?.length ? this.services[0] : null
        },

        src() {
            if (this.avatar?.url) return this.avatar.url
            return this.model?.cover
                ? `${this.apiUrl}/500/500/${this.model.cover.id}`
                : this.mappedImages?.length
                ? this.mappedImages[0]
                : this.model.perkImage || '/emptyspace_realweddings.png'
        },

        apiUrl() {
            return process.env.apiUrl + '/api/v1/attachments'
        },

        mappedImages() {
            return this.images
                .map((el) => {
                    return el.cover ? `${this.apiUrl}/500/500/${el.cover.id}` : undefined
                })
                .filter((el) => el)
        },

        gaEvent() {
            return {
                name: 'click',
                data: { method: 'Google', event_category: 'engagement', event_label: 'vendors', value: 0 },
            }
        },
    },

    mounted() {
        if (process.browser) window.addEventListener('resize', this.getRowsHeight)

        const rowsInterval = setInterval(() => {
            if (this.$refs?.mainImage) {
                this.getRowsHeight()
                clearInterval(rowsInterval)
            }
        }, 100)
    },

    destroyed() {
        if (process.browser) window.removeEventListener('resize', this.getRowsHeight)
    },

    methods: {
        getRowsHeight() {
            if (this.$refs?.mainImage) this.rowsHeight = this.$refs.mainImage.clientWidth / this.imageRatio + 'px'
        },
    },
}
