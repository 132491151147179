export default {
    computed: {
        taxonomy() {
            const seen = {}
            return this.model && this.model.taxonomy
                ? this.model.taxonomy.filter((el) => (Object.prototype.hasOwnProperty.call(seen, el.id) ? false : (seen[el.id] = true)))
                : []
        },

        children() {
            return this.model && this.model.children ? this.model.children : []
        },

        colors() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'colors'))
        },

        venues() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'venues'))
        },

        colorTones() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'color-tones'))
        },

        vendors() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'vendors'))
        },

        locations() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'locations'))
        },

        settings() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'settings'))
        },

        styles() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'styles'))
        },

        seasons() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'seasons'))
        },

        religions() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'religions'))
        },

        services() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'services'))
        },

        moments() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'moments'))
        },

        categories() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'categories'))
        },

        realWeddings() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'real-weddings'))
        },

        photographers() {
            return this.vendors.filter((el) => el.metas && el.metas.is_photographer && (el.metas.is_photographer === '1' || el.metas.is_photographer === 1))
        },

        posses() {
            return this.model && this.model.attached_users && this.model.attached_users.length ? this.model.attached_users : []
        },

        images() {
            return this.taxonomy
                .filter((el) => el.content_type && el.content_type.slug === 'wedding-inspiration' && el.cover)
                .concat(this.children.filter((el) => el.content_type && el.content_type.slug === 'wedding-inspiration' && el.cover))
        },
    },

    methods: {
        contentTypeLookup(obj, slug) {
            return obj && obj.content_type ? this.contentTypeLookupRecursive(obj.content_type, slug) : false
        },

        contentTypeLookupRecursive(obj, slug) {
            return obj && obj.slug === slug ? true : obj && obj.parent ? this.contentTypeLookupRecursive(obj.parent, slug) : false
        },
    },
}
